import * as WebBrowser from 'expo-web-browser';
import React, { CSSProperties } from 'react';
import { Dimensions, Linking, Platform, View } from 'react-native';
import {
  Headline,
  Paragraph,
  Subheading,
  Text,
  Title,
} from 'react-native-paper';

const IMAGES_MAX_WIDTH =
  Math.min(
    ...([720, Dimensions.get('window').width].filter(Boolean) as number[])
  ) - 32;
const CUSTOM_TAG_STYLES = {
  ul: { marginBottom: 0 },
  ol: { marginBottom: 0 },
  video: { maxWidth: IMAGES_MAX_WIDTH },
  embed: { maxWidth: IMAGES_MAX_WIDTH },
  object: { maxWidth: IMAGES_MAX_WIDTH },
  iframe: { maxWidth: IMAGES_MAX_WIDTH },
  img: {
    overflow: 'hidden',
    maxWidth: '100%',
    width: Platform.OS === 'android' ? '100%' : undefined,
  },
};

const CUSTOM_RENDERERS = {
  h1: (
    htmlAttribs: unknown,
    children: React.ReactNode,
    convertedCSSStyles: CSSProperties,
    { allowFontScaling, key }: { allowFontScaling: boolean; key: string }
  ) => (
    <Headline
      allowFontScaling={allowFontScaling}
      key={key}
      style={{ marginBottom: 8 }}
    >
      {children}
    </Headline>
  ),
  h2: (
    htmlAttribs: unknown,
    children: React.ReactNode,
    convertedCSSStyles: CSSProperties,
    { allowFontScaling, key }: { allowFontScaling: boolean; key: string }
  ) => (
    <Title
      allowFontScaling={allowFontScaling}
      key={key}
      style={{ marginBottom: 8 }}
    >
      {children}
    </Title>
  ),
  h3: (
    htmlAttribs: unknown,
    children: React.ReactNode,
    convertedCSSStyles: CSSProperties,
    { allowFontScaling, key }: { allowFontScaling: boolean; key: string }
  ) => (
    <Subheading
      allowFontScaling={allowFontScaling}
      key={key}
      style={{ marginBottom: 8 }}
    >
      {children}
    </Subheading>
  ),
  h4: (
    htmlAttribs: unknown,
    children: React.ReactNode,
    convertedCSSStyles: CSSProperties,
    { allowFontScaling, key }: { allowFontScaling: boolean; key: string }
  ) => (
    <Text
      allowFontScaling={allowFontScaling}
      key={key}
      style={{ marginBottom: 8, fontWeight: 'bold' }}
    >
      {children}
    </Text>
  ),
  h5: (
    htmlAttribs: unknown,
    children: React.ReactNode,
    convertedCSSStyles: CSSProperties,
    { allowFontScaling, key }: { allowFontScaling: boolean; key: string }
  ) => (
    <Text
      allowFontScaling={allowFontScaling}
      key={key}
      style={{ marginBottom: 8 }}
    >
      {children}
    </Text>
  ),
  h6: (
    htmlAttribs: unknown,
    children: React.ReactNode,
    convertedCSSStyles: CSSProperties,
    { allowFontScaling, key }: { allowFontScaling: boolean; key: string }
  ) => (
    <Text
      allowFontScaling={allowFontScaling}
      key={key}
      style={{ marginBottom: 8 }}
    >
      {children}
    </Text>
  ),
  p: (
    htmlAttribs: unknown,
    children: React.ReactNode,
    convertedCSSStyles: CSSProperties,
    { allowFontScaling, key }: { allowFontScaling: boolean; key: string }
  ) => (
    <Paragraph
      allowFontScaling={allowFontScaling}
      key={key}
      style={{ marginBottom: 8 }}
    >
      {children}
    </Paragraph>
  ),
  li: (
    htmlAttribs: unknown,
    children: React.ReactNode,
    convertedCSSStyles: CSSProperties,
    { allowFontScaling, key }: { allowFontScaling: boolean; key: string }
  ) => (
    <Text
      allowFontScaling={allowFontScaling}
      key={key}
      style={{ marginBottom: 0 }}
    >
      {children}
    </Text>
  ),
  blockquote: (
    htmlAttribs: unknown,
    children: React.ReactNode,
    convertedCSSStyles: CSSProperties,
    { allowFontScaling, key }: { allowFontScaling: boolean; key: string }
  ) => (
    <View
      key={key}
      style={{
        marginBottom: 8,
        paddingLeft: 16,
        /*borderLeftColor: BRAND_PINK,*/
        borderLeftWidth: 2,
      }}
    >
      <Paragraph
        allowFontScaling={allowFontScaling}
        key={key}
        style={{ marginBottom: 0 }}
      >
        {children}
      </Paragraph>
    </View>
  ),
};

const CUSTOM_PREFIX_RENDERERS = {
  ul: (
    htmlAttribs: unknown,
    children: React.ReactNode,
    convertedCSSStyles: CSSProperties,
    { allowFontScaling, key }: { allowFontScaling: boolean; key: string }
  ) => <Text style={{ /*color: BRAND_BLUE,*/ fontSize: 16 }}>+</Text>,
};

const CUSTOM_HTML_STYLES = {};

const ALTER_NODE = function (node: {
  name: string;
  attribs?: { width: number | string; height: number | string };
  parent?: unknown;
  children?: any[];
}) {
  const { name, attribs } = node;

  if (
    name === 'p' &&
    node.children &&
    node.children[0] &&
    node.children[0].name === 'img'
  ) {
    return node.children[0];
  }

  // Don't return anything (eg a falsy value) for anything else so nothing is altered
};

export const HTML_DEFAULT_PROPS = {
  classesStyles: CUSTOM_HTML_STYLES,
  tagsStyles: CUSTOM_TAG_STYLES,
  renderers: CUSTOM_RENDERERS,
  listPrefixRenderers: CUSTOM_PREFIX_RENDERERS,
  imagesMaxWidth: IMAGES_MAX_WIDTH,
  imagesInitialDimensions: { width: 200, height: 200 },
  onLinkPress: (evt: unknown, href: string) => {
    (WebBrowser && WebBrowser.openBrowserAsync(href)) || Linking.openURL(href);
  },
  containerStyle: {
    width: '100%',
    paddingTop: 8,
    paddingBottom: 0,
    paddingLeft: 16,
    paddingRight: 16,
  },
  alterNode: ALTER_NODE,
};
